.hidden {
  display: none !important;
}

// Buttons
.btn-primary {
  background-color: var(--color-primary);
  transition: all .5s ease;
  color: white;

  &:hover {
    transition: all .5s ease;
    background-color: var(--color-primary-hover);
  }

  &:active {
    transition: all .5s ease;
    background-color: var(--color-primary-active);
  }
}

// Background color helpers
.bg-warn {
  background-color: var(--color-warn);
}

.bg-primary {
  background-color: var(--color-primary);
  transition: all .5s ease;

  &:hover {
    transition: all .5s ease;
    background-color: var(--color-primary-hover);
  }

  &:active {
    transition: all .5s ease;
    background-color: var(--color-primary-active);
  }
}

.bg-success {
  background-color: var(--color-success);
}

.bg-info {
  background-color: var(--color-info);
}

.bg-accent {
  background-color: var(--color-inactive-1);
}

.bg-danger {
  background-color: var(--color-danger);
}

// Table helpers

.cell-center {
  text-align: center !important;

  ::ng-deep .mat-sort-header-container {
    justify-content: center !important;
  }
}

.cell-right {
  text-align: right !important;

  ::ng-deep .mat-sort-header-container {
    justify-content: right !important;
  }
}

.empty-table-row {
  height: 64px !important;

  &:hover {
    background-color: unset !important;
    cursor: initial !important;
  }

  td {
    text-align: center;
    color: var(--color-text-fade);
  }
}

// Flex helpers

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

$columns: 12;
@mixin columns-x {
  @for $i from 1 through $columns {
    .columns-#{$i} {
      display: grid;
      grid-template-columns: repeat($i, 1fr);
    }
  }
}
@include columns-x;

.gap {
  gap: 1rem;
}

// Material icons (instead of .material-icons use .icon)

.icon {
  font-family: 'Material Icons', 'Material Icons Outlined', 'Material Icons Round', 'Material Icons Sharp', 'Material Icons Two Tone', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

// Loaders

.is-loading {
  pointer-events: none;
  position: relative;
  background-color: inherit;

  &:after {
    box-sizing: border-box;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--color-background);
    opacity: 0.7;
    z-index: 199;
  }

  &:before {
    box-sizing: border-box;
    position: absolute;
    content: '';

    border-radius: 50%;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
    z-index: 200;

    border-width: 3px;
    border-style: solid;
    border-color: var(--color-primary);
    border-right: 3px solid transparent !important;

    animation: rotate 0.7s cubic-bezier(0.51, 0.57, 0.6, 0.62) infinite;
  }
}

button.is-loading {
  color: transparent !important;

  &:before {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-width: 2px;
    border-right: 2px solid transparent !important;
    border-color: white;
  }

  &.loading-dark {
    &:before {
      border-color: #333;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
