@use '@angular/cdk';
@include cdk.text-field-autofill();

html {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  height: 100%;
  font-family: var(--font-family), sans-serif;
  background-color: var(--color-background);
  color: var(--color-text);
}

h1 {
  font-size: 2rem;
  margin: 0 0 1.5rem;
}

h2 {
  font-size: 1.7rem;
  margin: 0 0 1rem;
}

input {
  line-height: 1;
  height: var(--height-input);
  padding: 0 1rem;
}

textarea {
  padding: 0.7rem 1rem;
  resize: vertical;
  min-height: var(--height-input);
}

select {
  padding: 0 1rem;
  height: var(--height-input);
}

hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-border);
}

input,
textarea,
select {
  font-size: 14px;
  font-family: var(--font-family);
  transition: border-color 150ms ease, border-radius 150ms ease, background-color 150ms ease;

  background-color: var(--color-background-2);
  color: var(--color-text);
  border-radius: var(--border-radius);
  width: 100%;

  border: 1px solid transparent;
  // border: 1px solid hsla(0, 0%, 0%, 0.1);
  // border-top-color: transparent;
  // border-left-color: transparent;
  // border-right-color: transparent;

  @include cdk.text-field-autofill-color(transparent, var(--color-text));

  &::placeholder {
    color: var(--color-text-fade);
  }

  &:focus {
    //border: 1px solid var(--color-primary);
    outline: none;
    border-radius: var(--border-radius);
  }
}

.ng-touched.ng-invalid:not(form) {
  border-radius: var(--border-radius);
  border-color: var(--color-danger);

  .ng-select-container {
    border-color: var(--color-danger);
  }

  &:hover,
  &:focus {
    border-color: var(--color-danger);
  }
}

.ng-valid + app-input-error,
.ng-untouched + app-input-error {
  display: none;
}

p {
  margin: 0 0 1rem;
}
